.header-content {
  position: absolute;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  z-index: 5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(19, 19, 19);
}

.header-logo {
  background: url("../images/nikeLogoWhite.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 50px;
  height: 25px;
  width: 65px;
}

nav {
  position: absolute;
  right: 0;
  display: flex;
  width: 70%;
  height: 100%;
  justify-content: space-between;
  background: rgb(233, 233, 233);
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  font-family: var(--font-primary);
}
.nav-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.nav-link span {
  font-weight: bold;
  color: rgb(153, 153, 153);
}

.nav-icons {
  display: flex;
  align-items: center;
  font-family: var(--font-primary);
  position: absolute;
  right: 0;
}
.nav-icon {
  margin: 0 25px;
  cursor: pointer;
  transition: all 0.3s;
  height: 20px;
}
.nav-icon:hover {
  transform: scale(1.3);
}
.svg-container {
  width: 75px;
  height: 75px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
svg {
  height: 30%;
  fill: white !important;
  transition: all 0.3s;
  cursor: pointer;
}
svg:hover {
  transform: scale(1.3);
}

.dropdown {
  overflow: hidden;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown:hover {
  border-bottom: 2px solid black;
}
.dropdown:hover .nav-link {
  opacity: 0.5;
}
.dropdown:hover .dropdown-content {
  display: flex;
}

.dropdown-content {
  display: none;
  justify-content: flex-start;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: initial;
}
.dropdown-link-container {
  margin: 3rem 1rem;
  border-left: 1px solid rgb(207, 207, 207);
  padding-left: 2rem;
}
.dropdown-link-container:first-of-type {
  border-left: none;
}
.dropdown-link:hover {
  color: rgb(117, 117, 117);
  cursor: pointer;
}
.major-link {
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}
.major-link:first-of-type {
  margin-top: 1rem;
}
.minor-link {
  font-family: var(--font-secondary);
  font-size: 0.8rem;
  margin: 0.3rem 0;
}
.minor-link ~ .major-link {
  margin-top: 1.5rem;
}

.col-2 {
  display: flex;
  width: 375px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.col-2 > div {
  margin: 0 0rem;
  margin-bottom: 1rem;
}
.col {
  width: 160px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .searchbox {
  position: relative;
  display: block;
  width: 100%;
  max-width: 40px;
  transition: 0.4s linear;
  overflow: hidden;
  margin-right: 20px;
}
.container .searchbox .searchbox__input {
  display: block;
  appearance: none;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px;
  color: rgba(255, 255, 255, 0);
  font-size: 20px;
  cursor: pointer;
  transition: 0.4s linear;
  height: 40px;
}
.container .searchbox .searchbox__input::placeholder {
  color: rgba(0, 0, 0, 0);
}
.container .searchbox .searchbox__input:focus {
  color: black;
}
.container .searchbox .searchbox__input:focus::placeholder {
  color: #5e5e5e;
}
.container .searchbox .searchbox__icon {
  position: absolute;
  right: calc(40px / 2);
  top: 50%;
  transform: translate(50%, -50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}
.container .searchbox .searchbox__icon path {
  fill: #000000;
  transition: 0.4s linear;
}
.container .searchbox:focus-within {
  max-width: 175px;
}
.container .searchbox:focus-within .searchbox__input {
  background-color: #fff;
  padding-right: 50px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.4);
}
.container .searchbox:focus-within .searchbox__icon path {
  fill: #969696;
}

@media screen and (max-width: 1150px) {
  .nav-links {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .header-logo {
    margin: 0 20px;
    height: 15px;
    width: 40px;
  }
  nav {
    width: 80%;
  }
}


