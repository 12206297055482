.home-landing {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.black-side {
  position: absolute;
  left: 0;
  width: 30%;
  min-width: 150px;
  height: 100%;
  background: rgb(19, 19, 19);
  z-index: 2;
}
.home-landing-social-links {
  display: flex;
  justify-content: space-between;
  width: clamp(125px, 15vw, 200px);
  color: white;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0px;
  margin: 25px 40px;
}
.home-landing-social-links > div {
  cursor: pointer;
}
.home-landing-social-links > div:hover {
  opacity: 0.5;
}
.scroll-indicator {
  position: absolute;
  right: 30px;
  bottom: 20px;
  color: white;
  font-size: 3.5rem;
  font-size: clamp(2.5rem, 3vw, 3.5rem);
}

.shoe-wrapper {
  position: absolute;
  height: clamp(200px, 55vw, 1100px);
  width: clamp(200px, 55vw, 1100px);
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.shoe-image {
  position: absolute;
  height: 100%;
  width: 100%;
  filter: drop-shadow(-25px 35px 20px #131313);
  z-index: 10;
  pointer-events: none;
}
.circle-backdrop {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  background: linear-gradient(200deg, rgb(238, 238, 238), rgb(36, 36, 36));
  box-shadow: 0px 0px 40px rgba(49, 49, 49, 0.5);
  pointer-events: none;
}

.white-side {
  position: absolute;
  right: 0;
  width: 70%;
  height: 100%;
}
.white-side-content-container {
  position: absolute;
  left: 65%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: clamp(350px, 40vw, 1000px);
  z-index: 3;
}
.white-side-text-container h2 {
  font-size: clamp(2rem, 5vw, 8rem);
  font-weight: 700;
  margin-bottom: 1.7rem;
  line-height: 1;
}
.white-side-text-container p {
  font-size: clamp(0.8rem, 2vw, 1rem);
  line-height: 1.7;
  width: 80%;
  color: rgb(110, 110, 110);
}
.white-side-text-container p:first-of-type {
  letter-spacing: 0.2rem;
  color: rgb(0, 0, 0);
}
button {
  background: black;
  border: none;
  color: white;
  font-size: 0.9rem;
  font-size: clamp(0.6rem, 2vw, 0.9rem);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  padding: 1rem 2.3rem;
  margin: 2rem 0;
  border-radius: 50px;
  cursor: pointer;
}
button:hover {
  background: rgb(37, 37, 37);
}
.backdrop-text {
  position: absolute;
  bottom: -100px;
  font-weight: 900;
  font-size: 15rem;
  white-space: nowrap;
  opacity: 0.3;
  color: rgb(206, 206, 206);
  text-transform: uppercase;
}
.rotated-90 {
  transform: rotate(90deg);
  bottom: 5px;
  left: -1050px;
  z-index: 1;
}

.shoe-image-container {
  display: flex;
}
.shoe-image-container * {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0.5rem;
  padding: 0.3rem;
  height: 70px;
  width: 85px;
  width: 70px;
  height: 60px;
  font-size: 3rem;
  cursor: pointer;
  transition: all 0.3s;
}
.shoe-image-container *:hover {
  transform: scale(1.1);
}
.shoe-image-container > img {
  background: linear-gradient(200deg, rgb(216, 216, 216), rgb(173, 173, 173));
  border-radius: 10px;
}

@media screen and (max-width: 975px) {
  .black-side {
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  .shoe-wrapper {
    top: 0%;
    left: 50%;
  }
  .white-side {
    top: 100px;
    width: 100%;
    height: 75%;
  }
  .white-side-content-container {
    left: 50%;
    top: 30%;
    width: 90%;
  }
  .white-side-text-container h2 {
    margin-bottom: 1rem;
  }
  .button {
    margin: 0rem;
  }
  .shoe-image-container * {
    margin: 0rem 0.5rem;
    height: 40px;
    width: 50px;
    font-size: 2rem;
  }
  .backdrop-text {
    display: none;
  }
}
