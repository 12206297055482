@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --font-primary: 'Oswald', sans-serif;
  --font-secondary: 'Open Sans', sans-serif;
}

h2 {
  font-family: 'Oswald', sans-serif;
  font-family: var(--font-primary);
  font-weight: 700;
  text-transform: uppercase;
}
p {
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-secondary);
}

body {
  background: rgb(233, 233, 233);
}
.header-content {
  position: absolute;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  z-index: 5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(19, 19, 19);
}

.header-logo {
  background: url(/static/media/nikeLogoWhite.0009cb9d.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 50px;
  height: 25px;
  width: 65px;
}

nav {
  position: absolute;
  right: 0;
  display: flex;
  width: 70%;
  height: 100%;
  justify-content: space-between;
  background: rgb(233, 233, 233);
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  font-family: var(--font-primary);
}
.nav-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.nav-link span {
  font-weight: bold;
  color: rgb(153, 153, 153);
}

.nav-icons {
  display: flex;
  align-items: center;
  font-family: var(--font-primary);
  position: absolute;
  right: 0;
}
.nav-icon {
  margin: 0 25px;
  cursor: pointer;
  transition: all 0.3s;
  height: 20px;
}
.nav-icon:hover {
  transform: scale(1.3);
}
.svg-container {
  width: 75px;
  height: 75px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
svg {
  height: 30%;
  fill: white !important;
  transition: all 0.3s;
  cursor: pointer;
}
svg:hover {
  transform: scale(1.3);
}

.dropdown {
  overflow: hidden;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown:hover {
  border-bottom: 2px solid black;
}
.dropdown:hover .nav-link {
  opacity: 0.5;
}
.dropdown:hover .dropdown-content {
  display: flex;
}

.dropdown-content {
  display: none;
  justify-content: flex-start;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: auto;
  cursor: initial;
}
.dropdown-link-container {
  margin: 3rem 1rem;
  border-left: 1px solid rgb(207, 207, 207);
  padding-left: 2rem;
}
.dropdown-link-container:first-of-type {
  border-left: none;
}
.dropdown-link:hover {
  color: rgb(117, 117, 117);
  cursor: pointer;
}
.major-link {
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}
.major-link:first-of-type {
  margin-top: 1rem;
}
.minor-link {
  font-family: var(--font-secondary);
  font-size: 0.8rem;
  margin: 0.3rem 0;
}
.minor-link ~ .major-link {
  margin-top: 1.5rem;
}

.col-2 {
  display: flex;
  width: 375px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.col-2 > div {
  margin: 0 0rem;
  margin-bottom: 1rem;
}
.col {
  width: 160px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .searchbox {
  position: relative;
  display: block;
  width: 100%;
  max-width: 40px;
  transition: 0.4s linear;
  overflow: hidden;
  margin-right: 20px;
}
.container .searchbox .searchbox__input {
  display: block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px;
  color: rgba(255, 255, 255, 0);
  font-size: 20px;
  cursor: pointer;
  transition: 0.4s linear;
  height: 40px;
}
.container .searchbox .searchbox__input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}
.container .searchbox .searchbox__input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}
.container .searchbox .searchbox__input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}
.container .searchbox .searchbox__input::placeholder {
  color: rgba(0, 0, 0, 0);
}
.container .searchbox .searchbox__input:focus {
  color: black;
}
.container .searchbox .searchbox__input:focus::-webkit-input-placeholder {
  color: #5e5e5e;
}
.container .searchbox .searchbox__input:focus:-ms-input-placeholder {
  color: #5e5e5e;
}
.container .searchbox .searchbox__input:focus::-ms-input-placeholder {
  color: #5e5e5e;
}
.container .searchbox .searchbox__input:focus::placeholder {
  color: #5e5e5e;
}
.container .searchbox .searchbox__icon {
  position: absolute;
  right: calc(40px / 2);
  top: 50%;
  transform: translate(50%, -50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}
.container .searchbox .searchbox__icon path {
  fill: #000000;
  transition: 0.4s linear;
}
.container .searchbox:focus-within {
  max-width: 175px;
}
.container .searchbox:focus-within .searchbox__input {
  background-color: #fff;
  padding-right: 50px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.4);
}
.container .searchbox:focus-within .searchbox__icon path {
  fill: #969696;
}

@media screen and (max-width: 1150px) {
  .nav-links {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .header-logo {
    margin: 0 20px;
    height: 15px;
    width: 40px;
  }
  nav {
    width: 80%;
  }
}



.home-landing {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.black-side {
  position: absolute;
  left: 0;
  width: 30%;
  min-width: 150px;
  height: 100%;
  background: rgb(19, 19, 19);
  z-index: 2;
}
.home-landing-social-links {
  display: flex;
  justify-content: space-between;
  width: clamp(125px, 15vw, 200px);
  color: white;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0px;
  margin: 25px 40px;
}
.home-landing-social-links > div {
  cursor: pointer;
}
.home-landing-social-links > div:hover {
  opacity: 0.5;
}
.scroll-indicator {
  position: absolute;
  right: 30px;
  bottom: 20px;
  color: white;
  font-size: 3.5rem;
  font-size: clamp(2.5rem, 3vw, 3.5rem);
}

.shoe-wrapper {
  position: absolute;
  height: clamp(200px, 55vw, 1100px);
  width: clamp(200px, 55vw, 1100px);
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.shoe-image {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-filter: drop-shadow(-25px 35px 20px #131313);
          filter: drop-shadow(-25px 35px 20px #131313);
  z-index: 10;
  pointer-events: none;
}
.circle-backdrop {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  background: linear-gradient(200deg, rgb(238, 238, 238), rgb(36, 36, 36));
  box-shadow: 0px 0px 40px rgba(49, 49, 49, 0.5);
  pointer-events: none;
}

.white-side {
  position: absolute;
  right: 0;
  width: 70%;
  height: 100%;
}
.white-side-content-container {
  position: absolute;
  left: 65%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: clamp(350px, 40vw, 1000px);
  z-index: 3;
}
.white-side-text-container h2 {
  font-size: clamp(2rem, 5vw, 8rem);
  font-weight: 700;
  margin-bottom: 1.7rem;
  line-height: 1;
}
.white-side-text-container p {
  font-size: clamp(0.8rem, 2vw, 1rem);
  line-height: 1.7;
  width: 80%;
  color: rgb(110, 110, 110);
}
.white-side-text-container p:first-of-type {
  letter-spacing: 0.2rem;
  color: rgb(0, 0, 0);
}
button {
  background: black;
  border: none;
  color: white;
  font-size: 0.9rem;
  font-size: clamp(0.6rem, 2vw, 0.9rem);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  padding: 1rem 2.3rem;
  margin: 2rem 0;
  border-radius: 50px;
  cursor: pointer;
}
button:hover {
  background: rgb(37, 37, 37);
}
.backdrop-text {
  position: absolute;
  bottom: -100px;
  font-weight: 900;
  font-size: 15rem;
  white-space: nowrap;
  opacity: 0.3;
  color: rgb(206, 206, 206);
  text-transform: uppercase;
}
.rotated-90 {
  transform: rotate(90deg);
  bottom: 5px;
  left: -1050px;
  z-index: 1;
}

.shoe-image-container {
  display: flex;
}
.shoe-image-container * {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0.5rem;
  padding: 0.3rem;
  height: 70px;
  width: 85px;
  width: 70px;
  height: 60px;
  font-size: 3rem;
  cursor: pointer;
  transition: all 0.3s;
}
.shoe-image-container *:hover {
  transform: scale(1.1);
}
.shoe-image-container > img {
  background: linear-gradient(200deg, rgb(216, 216, 216), rgb(173, 173, 173));
  border-radius: 10px;
}

@media screen and (max-width: 975px) {
  .black-side {
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  .shoe-wrapper {
    top: 0%;
    left: 50%;
  }
  .white-side {
    top: 100px;
    width: 100%;
    height: 75%;
  }
  .white-side-content-container {
    left: 50%;
    top: 30%;
    width: 90%;
  }
  .white-side-text-container h2 {
    margin-bottom: 1rem;
  }
  .button {
    margin: 0rem;
  }
  .shoe-image-container * {
    margin: 0rem 0.5rem;
    height: 40px;
    width: 50px;
    font-size: 2rem;
  }
  .backdrop-text {
    display: none;
  }
}

